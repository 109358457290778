@import '@/styles/artifacts.scss';





















































































































































































































































.value {
  display: flex;
  align-items: center;
  font-size: fontSize(fs-300);
}
.pipes {
  display: flex;
  overflow: auto;
}
.color.GREEN {
  color: colorVodafone(digital-green-active);
}
.color.RED {
  color: colorVodafone(vodafone-red);
}
.color.ORANGE {
  color: colorVodafone(fresh-orange);
}
