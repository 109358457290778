@import '@/styles/artifacts.scss';

































.app-act-aqua-pipe {
  text-align: center;
  width: 80px;
}
.temperature {
  font-size: fontSize(fs-100);
}
.app-act-aqua-pipe.GREY .temperature {
  color: colorVodafone(grey);
}
.app-act-aqua-pipe.RED .temperature {
  color: colorVodafone(vodafone-red);
}
.app-act-aqua-pipe.ORANGE .temperature {
  color: colorVodafone(fresh-orange);
}
.app-act-aqua-pipe.GREEN .svg-fill {
  fill: colorVodafone(digital-green);
}
.app-act-aqua-pipe.RED .svg-fill {
  fill: colorVodafone(vodafone-red);
}
.app-act-aqua-pipe.ORANGE .svg-fill {
  fill: colorVodafone(fresh-orange);
}
.label {
  color: colorVodafone(grey);
  font-size: fontSize(fs-50);
  @include textOverflow();
}
